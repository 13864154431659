import Joi from "@app/utils/joi";
import { CountrySchema, ICountry } from "./helper-schemas";

export const AGETCountrySchema = Joi.object({
	code: Joi.string().required(),
});

export interface IAGETCountry {
	code: string;
}

export const RGETCountrySchema = CountrySchema;
export type IRGETCountry = ICountry;

///

export const RGETCountriesSchema = Joi.array().items(CountrySchema);
export type IRGETCountries = ICountry[];
