import Joi from "@app/utils/joi";

export const CountrySchema = Joi.object({
	id: Joi.number().integer().required(),
	name: Joi.string().required(),
	code: Joi.string().required(),
});
export interface ICountry {
	id: number;
	name: string;
	code: string;
}
