import Joi from "@app/utils/joi";
import { ClassroomSchema, IClassroom } from "../classrooms/helper-schemas";
import {
	CourseInfoSchema,
	CourseSettingsSchema,
	ICourseInfo,
	ICourseSettings,
} from "../courses-info/helper-schemas";
import {
	IStudentInfo,
	IUserFullInfo,
	IUserShortInfo,
	StudentInfoSchema,
	UserFullInfoSchema,
	UserShortInfoSchema,
	UserTestStatus,
} from "./helper-schemas";
import { UserType, UserTypes } from "../helper-schemas";
import { SubjectSchema } from "../subjects/helper-schemas";
import { Subject } from "@app/models/subject";
import { GroupSchema, IGroup } from "../groups/helper-schemas";
//

// profile

export const RGETProfileInfoAsTeacher = Joi.object({
	classrooms: Joi.array().items(ClassroomSchema).required(),
	sentAssignments: Joi.number().required(),
	createAssignments: Joi.number().required(),
});

export interface IRGETProfileInfoAsTeacher {
	classrooms: IClassroom[];
	sentAssignments: number;
	createAssignments: number;
}

export const RGETProfileInfoAsStudent = Joi.object({
	subjects: Joi.array().items(SubjectSchema).required(),
	submitedAssignments: Joi.number().required,
});

export interface IRGETProfileInfoAsStudent {
	subjects: Subject[];
	submitedAssignments: number;
}
export type IRGETProfileInfoAsAdmin = void;

export type IRGETProfileInfo =
	| IRGETProfileInfoAsTeacher
	| IRGETProfileInfoAsStudent
	| IRGETProfileInfoAsAdmin;

//

export const RGETMyInfoAsTeacherSchema = Joi.object({
	classrooms: Joi.array().items(ClassroomSchema).required(),
	groups: Joi.array().items(GroupSchema).default([]), // TODO: mark required and remove default after 28 of September 2020
	users: Joi.array().items(UserShortInfoSchema).required(),
});

export interface IRGETMyInfoAsTeacher {
	classrooms: IClassroom[];
	groups: IGroup[];
	users: IUserShortInfo[];
}

///

export const RGETMyInfoAsStudentSchema = StudentInfoSchema.keys({
	classrooms: Joi.array()
		.items(
			ClassroomSchema.keys({
				numNewHomeworks: Joi.number().integer().min(0).required(),
				numIncompleteHomeworks: Joi.number()
					.integer()
					.min(0)
					.required(),
			})
		)
		.required(),
	courses: Joi.array().items(CourseInfoSchema).required(),
});

export interface IRGETMyInfoAsStudent extends IStudentInfo {
	classrooms: (IClassroom & {
		numNewHomeworks: number;
		numIncompleteHomeworks: number;
	})[];
	courses: ICourseInfo[];
}

///

export const RGETMyInfoAsMainAdminSchema = Joi.object({
	coursesInfo: Joi.array().items(CourseInfoSchema).required(),
	coursesSettings: Joi.array().items(CourseSettingsSchema).required(),
});
export interface IRGETMyInfoAsMainAdmin {
	coursesInfo: ICourseInfo[];
	coursesSettings: ICourseSettings[];
}

///

export type IRGETMyInfo =
	| IRGETMyInfoAsStudent
	| IRGETMyInfoAsTeacher
	| IRGETMyInfoAsMainAdmin;

///

export const RGETMyUsersInfoSchema = Joi.array().items(UserShortInfoSchema);
export type IRGETMyUsersInfo = IUserShortInfo[];

///

export const APUTSaveDetailedInfoSchema = Joi.object({
	country: Joi.string().allow(null),
	city: Joi.number().allow(null),
	cityName: Joi.string().allow(null),
	schoolName: Joi.string().allow(null),
	school: Joi.number().allow(null),
	grade: Joi.number().integer().greater(0),
	firstname: Joi.string(),
	lastname: Joi.string(),
	username: Joi.string(),
	newPassword: Joi.string(),
});

export interface IAPUTSaveDetailedInfo {
	country?: string | undefined | null;
	city?: number | undefined | null;
	cityName?: string | undefined | null;
	schoolName?: string | undefined | null;
	school?: number | undefined | null;
	grade?: number | undefined;
	firstname?: string | undefined;
	lastname?: string | undefined;
	username?: string | undefined;
	newPassword?: string | undefined;
}

export type IRPUTSaveDetailedInfo = void | IRGETMyInfoAsStudent;

///

export const AGETUserShortInfoSchema = Joi.object({
	userId: Joi.number().integer().required(),
});
export interface IAGETUserShortInfo {
	userId: number;
}

export const RGETUserShortInfoSchema = UserShortInfoSchema;
export type IRGETUserShortInfo = IUserShortInfo;

///

export const AGETUsersShortInfoSchema = Joi.object({
	userIds: Joi.array().items(Joi.number().integer()).required(),
});
export interface IAGETUsersShortInfo {
	userIds: number[];
}

export const RGETUsersShortInfoSchema = Joi.array().items(UserShortInfoSchema);
export type IRGETUsersShortInfo = IUserShortInfo[];

///

export const AGETUserShortInfoByMobileSchema = Joi.object({
	mobile: Joi.string().required(),
});
export interface IAGETUserShortInfoByMobile {
	mobile: string;
}

export const RGETUserShortInfoByMobileSchema = UserShortInfoSchema.keys({
	type: Joi.string().required(),
});
export type IRGETUserShortInfoByMobile = IUserShortInfo & { type: UserType };

///

export const AGETUsersShortInfoByMobilesSchema = Joi.object({
	info: Joi.array().items(Joi.string()).required(),
});
export interface IAGETUsersShortInfoByMobiles {
	info: string[];
}

export const RGETUsersShortInfoByMobilesSchema = Joi.array().items(
	RGETUserShortInfoByMobileSchema
);
export type IRGETUsersShortInfoByMobiles = IRGETUserShortInfoByMobile[];

///

export const RPOSTVerifyCodeSchema = Joi.object({
	token: Joi.string().required(),
});
export interface IRPOSTVerifyCode {
	token: string;
}

export interface IAPOSTVerifyCode {
	mobile?: string;
	userId?: number;
}

///

export const APUTVerifyMobileCodeSchema = Joi.object({
	code: Joi.string().required(),
	token: Joi.string().required(),
	mobile: Joi.string(),
});
export interface IAPUTVerifyMobileCode {
	code: string;
	token: string;
	mobile?: string;
}

///

export const AGETMobileExistenceSchema = Joi.object({
	mobile: Joi.string().required(),
});
export interface IAGETMobileExistence {
	mobile: string;
}
export const RGETMobileExistenceSchema = Joi.object({
	exists: Joi.boolean().required(),
});
export interface IRGETMobileExistence {
	exists: boolean;
}

export const APUTShowTermsOnLoginSchema = Joi.object({
	showTermsOnLogin: Joi.boolean().required(),
});
export interface IAPUTShowTermsOnLogin {
	showTermsOnLogin: boolean;
}

export const APUTHasAgreedOnTermsSchema = Joi.object({
	hasAgreedOnTerms: Joi.boolean().required(),
});

export interface IAPUTHasAgreedOnTerms {
	hasAgreedOnTerms: boolean;
}

////

export const ADELETEUserSchema = Joi.object({
	userId: Joi.number().required(),
});
export interface IADELETEUser {
	userId: number;
}

///

export const APUTChangeUserMobileSchema = Joi.object({
	userId: Joi.number().required(),
	newMobile: Joi.string().required(),
});

export interface IAPUTChangeUserMobile {
	userId: number;
	newMobile: string;
}

///

export const APUTChangeStudentGradeSchema = Joi.object({
	userId: Joi.number().required(),
	newGrade: Joi.number().required(),
});

export interface IAPUTChangeStudentGrade {
	userId: number;
	newGrade: number;
}

///

export const APUTChangeUserTypeSchema = Joi.object({
	userId: Joi.number().required(),
	newType: Joi.string()
		.valid(...UserTypes)
		.required(),
});

export interface IAPUTChangeUserType {
	userId: number;
	newType: UserType;
}

///

export interface IAPUTUserMobileValidation {
	userId: number;
	validate: boolean;
}

///
export const AGETUserFullInfoSchema = Joi.object({
	mobile: Joi.string().required(),
});
export interface IAGETUserFullInfo {
	mobile?: string;
	id?: number;
}
///

export interface IAGETUserProfileInfo {
	user_id: number;
}
export interface IAGETUserProfile {
	id: number;
	userId: number;
	userType: UserType;
	school: number | null;
	createdAt: Date;
	updatedAt: Date;
}
export const AGETUserProfileInfoSchema = Joi.object({
	user_id: Joi.number().required(),
});
export const RGETUserFullInfoSchema = UserFullInfoSchema;
export type IRGETUserFullInfo = IUserFullInfo;

///
export interface IAGETUserStatus {
	userId: number;
}

///

export interface IAGETManyUsersStatus {
	userIds: number[];
}

///

export interface IAPUTSaveTestUser {
	userId: number;
	status: UserTestStatus;
}

///
