import { IRequest } from "../requests";
import {
	IAGETCountry,
	IRGETCountries,
	IRGETCountry,
	RGETCountriesSchema,
	RGETCountrySchema,
} from "./validators";

export class CountriesController {
	private readonly Request: IRequest;

	constructor(request: IRequest) {
		this.Request = request;
	}

	getAll = async (): Promise<IRGETCountries> => {
		return this.Request.send("GET", "/api/countries", undefined, null, {
			responseSchema: RGETCountriesSchema,
		});
	};

	getByCode = async (code: string): Promise<IRGETCountry> => {
		const args: IAGETCountry = { code };
		return this.Request.send("GET", "/api/countries/:code", args, null, {
			responseSchema: RGETCountrySchema,
		});
	};
}
