import { useState, useEffect, useCallback, useMemo } from "react";
import { inject } from "@app/modules";
import { ICity } from "@app/api/cities/helper-schemas";
import { useFetch } from "./fetch";
import { ICountry } from "@app/api/countries/helper-schemas";

export const useCitiesAndSchools = (country: string | null) => {
	const [cities, setCities] = useState<{ value: number; label: string }[]>();
	const [schools, setSchools] = useState<
		{
			value: number;
			cityId: number;
			label: string;
		}[]
	>();

	useEffect(() => {
		const CitiesController = inject("CitiesController");
		const SchoolsController = inject("SchoolsController");
		CitiesController.getAll({ country }).then((cities) => {
			setCities(
				cities.map((city) => ({ value: city.id, label: city.name }))
			);
		});
		SchoolsController.getAll().then((schools) => {
			setSchools(
				schools.map((school) => ({
					value: school.id,
					label: school.name,
					cityId: school.city_id,
				}))
			);
		});
	}, [country]);
	return !cities || !schools
		? { cities: undefined, schools: undefined }
		: { cities, schools };
};

export const useCountries = () => {
	const [countries, setCountries] = useState<ICountry[]>();
	useEffect(() => {
		const CitiesController = inject("CountriesController");
		CitiesController.getAll().then((cities) => {
			setCountries(cities);
		});
	}, []);
	return countries;
};

export const useCountryOptions = () => {
	const countries = useCountries();
	return useMemo(() => {
		if (!countries) return undefined;
		return countries.map((e) => ({
			label: e.name,
			value: e.code,
		}));
	}, [countries]);
};

export const useCities = (country: string | null) => {
	const [cities, setCities] = useState<ICity[]>();
	useEffect(() => {
		const CitiesController = inject("CitiesController");
		CitiesController.getAll({ country }).then((cities) => {
			setCities(cities);
		});
	}, [country]);
	return cities;
};

export const useCityOptions = (country: string | null) => {
	const cities = useCities(country);
	return useMemo(() => {
		if (!cities) return undefined;
		return cities.map((e) => ({
			label: e.name,
			value: e.id,
		}));
	}, [cities]);
};

export const useGetCityById = (country: string | null) => {
	const cities = useCities(country);
	return useCallback(
		(id: number | undefined) => {
			return id ? cities?.find((city) => city.id === id) : undefined;
		},
		[cities]
	);
};

export const useSchools = (country: string | null) => {
	const [schools, setSchools] = useState<
		{
			value: number;
			cityId: number;
			label: string;
		}[]
	>();

	useEffect(() => {
		const SchoolsController = inject("SchoolsController");
		SchoolsController.getAll().then((schools) => {
			setSchools(
				schools.map((school) => ({
					value: school.id,
					label: school.name,
					cityId: school.city_id,
				}))
			);
		});
	}, [country]);

	return schools;
};

export const useManySchoolsByIds = (ids: number[] | null) => {
	const SchoolsController = inject("SchoolsController");
	return useFetch(() => SchoolsController.getManyByIds({ ids: ids! }), [ids]);
};

export const useSchoolById = (id: number | null | undefined) =>
	useFetch(() => inject("SchoolsController").getById(id!), [id]);

export const useCityById = (id: number | null | undefined) =>
	useFetch(() => inject("CitiesController").getById(id!), [id]);
