/* eslint-disable max-lines-per-function */
import { IModel } from "m-model-core";
import { Store } from "redux";
import { AdminController } from "./api/admin/controller";
import { AdminDatabaseController } from "./api/admin/databases/controller";
import { AppealsController } from "./api/appeals/controller";
import { ComplexAssignmentController } from "./api/assignments/complex/controller";
import { AssignmentsController } from "./api/assignments/controller";
import { AssignmentGradeController } from "./api/assignments/grades/controller";
import { AuthController } from "./api/auth/controllers";
import { BlogController } from "./api/blogs/controllers";
import { CardTemplatesController } from "./api/card-templates/controllers";
import { CardsController } from "./api/cards/controllers";
import { CertificatesController } from "./api/certificates/controller";
import { CitiesController } from "./api/cities/controllers";
import { ClassroomsController } from "./api/classrooms/controllers";
import { ClassroomEnrollmentCodesController } from "./api/classrooms/enrollments/controller";
import { ClassroomJoinRequestsController } from "./api/classrooms/join-requests/controllers";
import { ConferencesController } from "./api/conferences/controllers";
import { CoursesInfoController } from "./api/courses-info/controllers";
import { CoursesController } from "./api/courses/controllers";
import CourseFetchingController from "./api/courses/fetch-controllers";
import { FeedbackController } from "./api/feedback/controller";
import { FilesController } from "./api/files/controllers";
import { FoldersController } from "./api/folders/controllers";
import { FolderlessItemsController } from "./api/folders/folderless-items/controller";
import FriendInvitationController from "./api/friend-invitation/controller";
import { StudentGradeCategoryController } from "./api/gradebooks/categories/controller";
import { GradebookController } from "./api/gradebooks/controller";
import { TeacherCachedGradesController } from "./api/gradebooks/teacher_cached_grades/controller";
import { GradesController } from "./api/grades/controller";
import { GroupController } from "./api/groups/controller";
import { PhotosController } from "./api/photos/controllers";
import { ProposalsController } from "./api/proposals/controller";
import { PublicTestController } from "./api/public-tests/controller";
import { QuestionsController } from "./api/questions/controllers";
import { CourseRedirectionController } from "./api/redirection/course-controller";
import { IRequest } from "./api/requests";
import { ReservationController } from "./api/reservations/controller";
import { SchoolsController } from "./api/schools/controllers";
import { SpecialTestsController } from "./api/special-tests/controller";
import { UserStatsController } from "./api/stats/controller";
import { SubjectsController } from "./api/subjects/controllers";
import { TaskTypesController } from "./api/task-types/controller";
import { TeacherRegistrationController } from "./api/teacher-registration-requests/controllers";
import { TestTypesController } from "./api/test-types/controllers";
import { TestsController } from "./api/tests/controller";
import { TopicsController } from "./api/topics/controllers";
import { UserActionsController } from "./api/user-actions/controller";
import { UserRequestsController } from "./api/user-requests/controller";
import { UserViewsController } from "./api/user-views/controller";
import { UsersController } from "./api/users/controller";
import { VideoLessonsController } from "./api/video-lessons/controllers";
import { Appeal } from "./models/appeal";
import { Assignment } from "./models/assignment";
import { Blog } from "./models/blog";
import { Card } from "./models/card";
import { CardTemplate } from "./models/card-template";
import { Classroom } from "./models/classroom";
import { ClassroomJoinRequest } from "./models/classroom-join-request";
import { Course } from "./models/course";
import { CourseInfo } from "./models/course-info";
import { CourseSettings } from "./models/course-settings";
import { FeedbackModel } from "./models/feedback";
import { File } from "./models/file";
import { Folder } from "./models/folder";
import { FolderHierarchy } from "./models/folder-hierarchy";
import { Grade } from "./models/grade";
import { GradebookCategoryModel } from "./models/grade-book-category";
import { GradeCategory } from "./models/grade-category";
import { Group } from "./models/group";
import { Reservation } from "./models/reservation";
import { School } from "./models/schools";
import { StudentInfo } from "./models/student-info";
import { Subject } from "./models/subject";
import { TaskType } from "./models/task-type";
import { TaskTypeHierarchy } from "./models/task-type-hierarchy";
import { Test } from "./models/test";
import { TestType } from "./models/test-type";
import { Topic } from "./models/topic";
import { TopicHierarchy } from "./models/topic-hierarchy";
import { UserAction } from "./models/user-action";
import { UserFolderLevel } from "./models/user-folder-level";
import { UserFolderProgress } from "./models/user-folder-progress";
import { UserInfo } from "./models/user-info";
import { UserTaskTypeLevel } from "./models/user-task-type-level";
import { UserTopicLevel } from "./models/user-topic-level";
import { UserView } from "./models/user-view";
import { VideoLesson } from "./models/video-lesson";
import { IRootActions, IRootState } from "./redux";
import { AssignmentPermissionsService } from "./services/assignments/permissions";
import { CountriesService } from "./services/countries";
import FolderItemsService from "./services/folder-items";
import UserFolderProgressService from "./services/folder-progress";
import UserProgressCalculationService from "./services/folder-progress/calculation";
import FolderItemProgressService from "./services/folder-progress/items";
import UserFolderProgressResetService from "./services/folder-progress/reset";
import FolderHierarchyService from "./services/hierarchy-info/folders";
import TaskTypeHierarchyService from "./services/hierarchy-info/task-types";
import TopicHierarchyService from "./services/hierarchy-info/topics";
import { ClassroomJoinRequestsService } from "./services/join-requests";
import TestItemsService from "./services/tests/items";
import { User } from "./user";
import { CoursesUser } from "./user/courses-user";
import { getLoc } from "./utils/loc";
import { SchoolLessonScheduleController } from "./api/schools/lesson-schedules/controller";
import { SchoolLessonSchedule } from "./models/school-lesson-schedule";
import { WeeklyTestController } from "./api/weekly-tests/controller";
import { WeeklyTestSubjectController } from "./api/weekly-tests/subjects/controller";
import { MobileConfirmationController } from "./api/mobile-confirmation/controller";
import { WeeklyTestReservation } from "./models/weekly-test-reservation";
import { OnlinePaymentController } from "./api/online-payments/controller";
import { FindUserController } from "./api/find-user/controller";
import { ParentChildrenController } from "./api/parent-children/controller";
import { Summons } from "./models/summons";
import { SummonsesController } from "./api/summonses/controller";
import { SchoolStructure } from "./models/school-structure";
import { SchoolStructureController } from "./api/schools/structure/controller";
import { ChatController } from "./api/chats/controller";
import { Conference } from "./models/conference";
import { AudeincesController } from "./api/audiences/controller";
import { CommentsController } from "./api/comments/controller";
import { FinalStatsController } from "./api/final-stats/controller";
import { CountriesController } from "./api/countries/controllers";

export const Container = {
	// --------------------------- MODELS ---------------------------
	CourseModel: Course,
	CardTemplateModel: CardTemplate,
	CardModel: Card,
	FileModel: File,
	FolderModel: Folder,
	TopicModel: Topic,
	TestTypeModel: TestType,
	UserFolderProgressModel: UserFolderProgress,
	FolderHierarchyModel: FolderHierarchy,
	TopicHierarchyModel: TopicHierarchy,
	TaskTypeModel: TaskType,
	TaskTypeHierarchyModel: TaskTypeHierarchy,
	TestModel: Test,
	SubjectModel: Subject,
	ClassroomModel: Classroom,
	AssignmentModel: Assignment,
	CourseInfoModel: CourseInfo,
	StudentInfoModel: StudentInfo,
	UserInfoModel: UserInfo,
	ClassroomJoinRequestModel: ClassroomJoinRequest,
	UserFolderLevelModel: UserFolderLevel,
	UserTaskTypeLevelModel: UserTaskTypeLevel,
	UserTopicLevelModel: UserTopicLevel,
	UserViewModel: UserView,
	UserActionModel: UserAction,
	BlogModel: Blog,
	CourseSettingsModel: CourseSettings,
	AppealModel: Appeal,
	GradeModel: Grade,
	GradeCategoryModel: GradeCategory,
	FeedbackModel: FeedbackModel,
	ReservationModel: Reservation,
	GradeBookCategoryModel: GradebookCategoryModel,
	VideoLessonModel: VideoLesson,
	SchoolModel: School,
	GroupModel: Group,
	SchoolLessonScheduleModel: SchoolLessonSchedule,
	WeeklyTestReservationModel: WeeklyTestReservation,
	SummonsModel: Summons,
	SchoolStructureModel: SchoolStructure,
	ConferenceModel: Conference,
	// --------------------------- SERVICES ---------------------------
	FolderItemsService: {} as FolderItemsService,
	UserFolderProgressService: {} as UserFolderProgressService,
	UserProgressCalculationService: {} as UserProgressCalculationService,
	FolderHierarchyService: {} as FolderHierarchyService,
	FolderItemProgressService: {} as FolderItemProgressService,
	UserFolderProgressResetService: {} as UserFolderProgressResetService,
	TopicHierarchyService: {} as TopicHierarchyService,
	TaskTypeHierarchyService: {} as TaskTypeHierarchyService,
	ClassroomJoinRequestsService: {} as ClassroomJoinRequestsService,
	AssignmentPermissionsService: {} as AssignmentPermissionsService,
	TestItemsService: {} as TestItemsService,
	CountriesService: {} as CountriesService,
	// --------------------------- CONTROLLERS ---------------------------
	CourseRedirectionController: {} as CourseRedirectionController,
	AuthController: {} as AuthController,
	AdminController: {} as AdminController,
	UsersController: {} as UsersController,
	CardTemplatesController: {} as CardTemplatesController,
	CardsController: {} as CardsController,
	CoursesController: {} as CoursesController,
	FilesController: {} as FilesController,
	FoldersController: {} as FoldersController,
	TestTypesController: {} as TestTypesController,
	QuestionsController: {} as QuestionsController,
	TestsController: {} as TestsController,
	TopicsController: {} as TopicsController,
	TaskTypesController: {} as TaskTypesController,
	CourseFetchingController: {} as CourseFetchingController,
	SubjectsController: {} as SubjectsController,
	ClassroomsController: {} as ClassroomsController,
	AssignmentsController: {} as AssignmentsController,
	CoursesInfoController: {} as CoursesInfoController,
	ClassroomJoinRequestsController: {} as ClassroomJoinRequestsController,
	TeacherRegistrationController: {} as TeacherRegistrationController,
	CitiesController: {} as CitiesController,
	CountriesController: {} as CountriesController,
	SchoolsController: {} as SchoolsController,
	UserViewsController: {} as UserViewsController,
	UserStatsController: {} as UserStatsController,
	UserActionsController: {} as UserActionsController,
	SpecialTestsController: {} as SpecialTestsController,
	BlogController: {} as BlogController,
	PhotosController: {} as PhotosController,
	FriendInvitationController: {} as FriendInvitationController,
	AppealsController: {} as AppealsController,
	UserRequestController: {} as UserRequestsController,
	ProposalsController: {} as ProposalsController,
	PublicTestsController: {} as PublicTestController,
	FolderlessItemsController: {} as FolderlessItemsController,
	CertificatesController: {} as CertificatesController,
	GradesController: {} as GradesController,
	ClassroomEnrollmentCodesController:
		{} as ClassroomEnrollmentCodesController,
	AdminDatabaseController: {} as AdminDatabaseController,
	ComplexAssignmentController: {} as ComplexAssignmentController,
	FeedbackController: {} as FeedbackController,
	ReservationController: {} as ReservationController,
	GradebookController: {} as GradebookController,
	StudentGradeCategoryController: {} as StudentGradeCategoryController,
	TeacherCachedGradesController: {} as TeacherCachedGradesController,
	VideoLessonsController: {} as VideoLessonsController,
	GroupController: {} as GroupController,
	AssignmentGradeController: {} as AssignmentGradeController,
	ConferencesController: {} as ConferencesController,
	SchoolLessonScheduleController: {} as SchoolLessonScheduleController,
	WeeklyTestController: {} as WeeklyTestController,
	WeeklyTestSubjectController: {} as WeeklyTestSubjectController,
	MobileConfirmationController: {} as MobileConfirmationController,
	OnlinePaymentController: {} as OnlinePaymentController,
	FindUserController: {} as FindUserController,
	ParentChildrenController: {} as ParentChildrenController,
	SummonsesController: {} as SummonsesController,
	SchoolStructureController: {} as SchoolStructureController,
	ChatController: {} as ChatController,
	AudeincesController: {} as AudeincesController,
	CommentsController: {} as CommentsController,
	FinalStatsController: {} as FinalStatsController,
	// --------------------------- SPECIALS ---------------------------
	// getUser: ((() => {}) as any) as () => IStateUser,
	assertAndGetClassroomsUser: (() => {
		//
	}) as any as () => User,
	assertAndGetCoursesUser: (() => {
		//
	}) as any as () => CoursesUser,
	rootDispatch: (() => {
		//
	}) as any as (action: IRootActions) => void,
	getAllModels: (): IModel[] => {
		const keys = Object.keys(Container);
		const models: IModel[] = [];
		const modelLength = "Model".length;
		for (const key of keys) {
			const lastIndex = key.lastIndexOf("Model");
			if (lastIndex === key.length - modelLength) {
				models.push(Container[key as any] as any as IModel);
			}
		}
		return models;
	},
	store: {} as Store<IRootState, IRootActions>,
	getLoc,
};

export function createModules({
	store,
	coursesRequest,
	classroomsRequest,
}: {
	store: Store<IRootState, IRootActions>;
	coursesRequest: IRequest;
	classroomsRequest: IRequest;
}) {
	// --------------------------- SPECIALS ---------------------------
	// Container.getUser = () => store.getState()["user"];
	Container.store = store;
	Container.assertAndGetClassroomsUser = () => {
		const user = store.getState()["user"];
		if (!user.loggedIn) {
			throw new Error("user must be logged in");
		}
		return User.createUserInstance(user.userData);
	};
	Container.assertAndGetCoursesUser = () => {
		const user = store.getState()["user"];
		if (!user.loggedIn) {
			throw new Error("user must be logged in");
		}
		return CoursesUser.createUserInstance(user.coursesUserData);
	};
	Container.rootDispatch = store.dispatch;
	// --------------------------- MODELS ---------------------------
	Container.CourseModel.initialize();
	Container.CardModel.initialize();
	Container.CardTemplateModel.initialize();
	// Container.FileModel.initialize();
	// Container.FolderModel.initialize();
	Container.FolderHierarchyModel.initialize();
	Container.TestTypeModel.initialize();
	Container.TopicModel.initialize();
	Container.TopicHierarchyModel.initialize();
	Container.UserFolderProgressModel.initialize();
	Container.UserFolderProgressModel.synchronizeNotFoundFoldersWithMetadata();
	Container.TaskTypeModel.initialize();
	Container.TaskTypeHierarchyModel.initialize();
	Container.SubjectModel.initialize();
	Container.ClassroomModel.initialize();
	Container.AssignmentModel.initialize();
	Container.CourseInfoModel.initialize();
	Container.StudentInfoModel.initialize();
	Container.UserInfoModel.initialize();
	Container.ClassroomJoinRequestModel.initialize();
	Container.UserFolderLevelModel.initialize();
	Container.UserTaskTypeLevelModel.initialize();
	Container.UserTopicLevelModel.initialize();
	Container.UserViewModel.initialize();
	Container.UserActionModel.initialize();
	Container.CourseSettingsModel.initialize();
	Container.AppealModel.initialize();
	Container.GradeModel.initialize();
	Container.GradeCategoryModel.initialize();
	Container.GradeBookCategoryModel.initialize();
	// Container.VideoLessonModel.initialize();
	// Container.ConferenceModel.initialize();
	Container.SchoolModel.initialize();
	Container.SchoolStructureModel.initialize();

	// --------------------------- SERVICES ---------------------------
	Container.FolderHierarchyService = new FolderHierarchyService();
	Container.TopicHierarchyService = new TopicHierarchyService();
	Container.TaskTypeHierarchyService = new TaskTypeHierarchyService();
	Container.FolderItemsService = new FolderItemsService();
	Container.UserFolderProgressService = new UserFolderProgressService();
	Container.FolderItemsService._UserFolderProgressService =
		Container.UserFolderProgressService;
	Container.UserProgressCalculationService =
		new UserProgressCalculationService();
	Container.UserFolderProgressService._UserProgressCalculation =
		Container.UserProgressCalculationService;
	Container.FolderItemProgressService = new FolderItemProgressService();
	Container.UserFolderProgressResetService =
		new UserFolderProgressResetService();
	Container.ClassroomJoinRequestsService = new ClassroomJoinRequestsService();
	Container.AssignmentPermissionsService = new AssignmentPermissionsService();
	Container.TestItemsService = new TestItemsService();
	Container.CountriesService = new CountriesService();

	// --------------------------- CONTROLLERS ---------------------------
	Container.CourseRedirectionController = new CourseRedirectionController(
		classroomsRequest,
		coursesRequest
	);
	Container.AuthController = new AuthController(
		classroomsRequest,
		coursesRequest
	);
	Container.AdminController = new AdminController(
		classroomsRequest,
		coursesRequest
	);
	Container.UsersController = new UsersController(classroomsRequest);
	Container.CardTemplatesController = new CardTemplatesController(
		coursesRequest
	);
	Container.CardsController = new CardsController(coursesRequest);
	Container.CoursesController = new CoursesController(coursesRequest);
	Container.FilesController = new FilesController(coursesRequest);
	Container.FoldersController = new FoldersController(coursesRequest);
	Container.FolderlessItemsController = new FolderlessItemsController(
		coursesRequest
	);
	Container.TestTypesController = new TestTypesController(coursesRequest);
	Container.QuestionsController = new QuestionsController(coursesRequest);
	Container.TestsController = new TestsController(coursesRequest);
	Container.TopicsController = new TopicsController(coursesRequest);
	Container.TaskTypesController = new TaskTypesController(coursesRequest);
	Container.CourseFetchingController = new CourseFetchingController(
		coursesRequest
	);
	Container.SubjectsController = new SubjectsController(classroomsRequest);
	Container.ClassroomsController = new ClassroomsController(
		classroomsRequest
	);
	Container.AssignmentsController = new AssignmentsController(
		classroomsRequest,
		coursesRequest
	);
	Container.CoursesInfoController = new CoursesInfoController(
		classroomsRequest
	);
	Container.ClassroomJoinRequestsController =
		new ClassroomJoinRequestsController(classroomsRequest);
	Container.TeacherRegistrationController = new TeacherRegistrationController(
		classroomsRequest
	);
	Container.CitiesController = new CitiesController(classroomsRequest);
	Container.CountriesController = new CountriesController(classroomsRequest);
	Container.SchoolsController = new SchoolsController(classroomsRequest);
	Container.UserViewsController = new UserViewsController(classroomsRequest);
	Container.UserStatsController = new UserStatsController(
		classroomsRequest,
		coursesRequest
	);
	Container.UserActionsController = new UserActionsController(
		classroomsRequest
	);
	Container.SpecialTestsController = new SpecialTestsController(
		classroomsRequest
	);
	Container.BlogController = new BlogController(classroomsRequest);
	Container.PhotosController = new PhotosController(classroomsRequest);
	Container.FriendInvitationController = new FriendInvitationController(
		classroomsRequest
	);
	Container.AppealsController = new AppealsController(coursesRequest);
	Container.UserRequestController = new UserRequestsController(
		classroomsRequest
	);
	Container.ProposalsController = new ProposalsController(classroomsRequest);
	Container.PublicTestsController = new PublicTestController(
		classroomsRequest
	);
	Container.CertificatesController = new CertificatesController(
		classroomsRequest
	);
	Container.GradesController = new GradesController(classroomsRequest);
	Container.ClassroomEnrollmentCodesController =
		new ClassroomEnrollmentCodesController(classroomsRequest);
	Container.AdminDatabaseController = new AdminDatabaseController(
		classroomsRequest
	);
	Container.ComplexAssignmentController = new ComplexAssignmentController(
		classroomsRequest
	);

	Container.FeedbackController = new FeedbackController(classroomsRequest);
	Container.ReservationController = new ReservationController(
		classroomsRequest
	);
	Container.GradebookController = new GradebookController(classroomsRequest);
	Container.StudentGradeCategoryController =
		new StudentGradeCategoryController(classroomsRequest);
	Container.VideoLessonsController = new VideoLessonsController(
		classroomsRequest
	);
	Container.GroupController = new GroupController(classroomsRequest);
	Container.AssignmentGradeController = new AssignmentGradeController(
		classroomsRequest
	);
	Container.TeacherCachedGradesController = new TeacherCachedGradesController(
		classroomsRequest
	);
	Container.ConferencesController = new ConferencesController(
		classroomsRequest
	);
	Container.SchoolLessonScheduleController =
		new SchoolLessonScheduleController(classroomsRequest);
	Container.WeeklyTestController = new WeeklyTestController(
		classroomsRequest
	);
	Container.WeeklyTestSubjectController = new WeeklyTestSubjectController(
		classroomsRequest
	);
	Container.MobileConfirmationController = new MobileConfirmationController(
		classroomsRequest
	);
	Container.OnlinePaymentController = new OnlinePaymentController(
		classroomsRequest
	);
	Container.FindUserController = new FindUserController(classroomsRequest);
	Container.ParentChildrenController = new ParentChildrenController(
		classroomsRequest
	);
	Container.SummonsesController = new SummonsesController(classroomsRequest);
	Container.SchoolStructureController = new SchoolStructureController(
		classroomsRequest
	);

	Container.AudeincesController = new AudeincesController(classroomsRequest);

	Container.ChatController = new ChatController(classroomsRequest);
	Container.CommentsController = new CommentsController(classroomsRequest);
	Container.FinalStatsController = new FinalStatsController(
		classroomsRequest
	);
}

export function inject<T extends keyof typeof Container>(
	key: T
): (typeof Container)[T] {
	return Container[key];
}

(window as any).mainRootContainer = Container;
